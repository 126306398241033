import { Component, OnInit } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'Loader',
  templateUrl: './loader.component.html',
  standalone:true,
  imports:[
    IconComponent
  ]
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
