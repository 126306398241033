import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private componentRef!: ComponentRef<any>;
  originalBodyOverflow!: any;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  render(component: any) {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as any)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    domElem.focus();
    domElem.style.position = 'absolute';
    domElem.style.zIndex = '9999';
    this.originalBodyOverflow = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return this.componentRef;
  }

  destroy() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      // document.body.style.overflow = this.originalBodyOverflow;
      document.body.style.overflow = '';
    }
  }
}
