import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceInfo: any;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  isTV(): boolean {
    // Você pode usar user agent para detectar TVs, mas isso não é 100% garantido
    const userAgent = this.deviceInfo.userAgent.toLowerCase();
    return userAgent.includes('smart-tv') || userAgent.includes('hbbtv') || userAgent.includes('netcast') || userAgent.includes('tizen');
  }

  get():string{
    if (this.isMobile()) {
      return 'mobile';
    } else if (this.isTablet()) {
      return 'tablet';
    } else if (this.isTV()) {
      return 'tv';
    } else if (this.isDesktop()) {
      return 'desktop';
    } else {
      return 'unknown';
    }
  }
}
