<div class="relative">

  <Loader *ngIf="loading" class="text-sm absolute inset-0 flex items-center justify-center" />

  <button [type]="typed" [disabled]="disabled" [class]="styleClass" [ngClass]="{'opacity-50': loading}">
    <div class="flex flex-row items-center justify-center">
      @if(key && deviceService.get() !== 'mobile'){
      <div class="inset-0 flex items-center justify-center me-2" [style.fontSize]="'10px'">[{{key}}]</div>
      }
      <ng-content></ng-content>
    </div>
  </button>

</div>
