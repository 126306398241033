import { DeviceService } from './../../services/device.service';
import { Component, Input } from '@angular/core';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'Btn',
  standalone: true,
  imports: [LoaderComponent, CommonModule],
  templateUrl: './btn.component.html',
  styleUrl: './btn.component.scss'
})
export class BtnComponent {

  @Input() key!:string;
  @Input() disabled:boolean = false;
  @Input() loading:boolean = false;
  @Input() typed: string = 'button';
  @Input() styler: string = 'default';

  constructor(public deviceService:DeviceService){}

  get styleClass() {

    let classBase = "w-full p-2 rounded-sm md:rounded-md cursor-pointer transition-all disabled:opacity-40 min-h-8 shadow-md shadow-econo-secondary-00 hover:shadow-slate-200 active:shadow-sm active:shadow-black active:opacity-70 ";

    switch (this.styler) {

      case 'transparent':
        return classBase + 'bg-transparent hover:bg-slate-200 text-gray-500';
      break;

      case 'white':
        return classBase + 'bg-white hover:bg-slate-300 text-gray-500';
      break;
      case 'primary':
        return classBase + "bg-econo-primary-500 hover:bg-econo-primary-300 text-white";
        break;
      default:
      case 'secondary':
        return classBase + "bg-econo-secondary-500 hover:bg-econo-secondary-300 text-white";
        break;
      case 'success':
        return classBase + "bg-econo-success-500 hover:bg-econo-success-300 text-white";
        break;
      case 'danger':
        return classBase + 'bg-econo-danger-500 hover:bg-econo-danger-300 text-white';
        break;
      case 'warning':
        return classBase + 'bg-econo-warning-500 hover:bg-econo-warning-300 text-white';
        break;
      case 'info':
        return classBase + 'bg-econo-info-500 hover:bg-econo-info-300 text-white';
        break;


      case 'orange':
          return classBase + 'bg-orange-400 hover:bg-orange-300 dark:bg-orange-600 dark:hover:bg-orange-700 text-white';
          break;

      case 'secondary-info':
        return classBase + 'bg-gray-200 dark:bg-gray-500 dark:text-white hover:bg-blue-500 hover:text-white';
        break;
      case 'secondary-danger':
        return classBase + 'bg-gray-200 dark:bg-gray-500 dark:text-white hover:bg-red-500 hover:text-white';
        break;
      case 'secondary-orange':
        return classBase + 'bg-gray-200 dark:bg-gray-500 dark:text-white hover:bg-orange-500 hover:text-white';
        break;
      case 'green':
        return classBase + "bg-green-600 hover:bg-green-500 dark:bg-green-800 dark:hover:bg-green-900 text-white";
        break;
    }
  }
}
